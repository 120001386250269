import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="360"
      height="62"
      version="1.1"
      viewBox="0 0 820.27 48.322"
    >
      <linearGradient x1="0.3" x2="-0.1" y1="1.2" y2="0.3" spreadMethod="pad">
        <stop offset="0%" stopColor="#fff" stopOpacity="1"></stop>
        <stop offset="100%" stopColor="#000" stopOpacity="1"></stop>
      </linearGradient>
      <linearGradient
        x1="0.3"
        x2="-0.1"
        y1="1.2"
        y2="0.3"
        gradientTransform="rotate(-30)"
        spreadMethod="pad"
      >
        <stop offset="0%" stopColor="#fff" stopOpacity="1"></stop>
        <stop offset="50%" stopColor="#ccc" stopOpacity="1"></stop>
        <stop offset="100%" stopColor="#000" stopOpacity="1"></stop>
      </linearGradient>
      <g transform="translate(-101.878 -359.833)">
        <image width="480" height="480" x="272" y="144" display="none"></image>
        <g>
          <image x="0" y="0" display="none"></image>
          <g
            style={{
              lineHeight: '1',
              fontVariantLigatures: 'normal',
              WebkitTextAlign: 'center',
              textAlign: 'center',
            }}
            fontFamily="Brandmark1 Bold Color"
            fontSize="72"
            fontStyle="normal"
            fontWeight="700"
            textAnchor="middle"
          >
            <g ariaLabel="Q" transform="matrix(.75 0 0 .75 30.884 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M137.83-13.49a16.987 16.987 0 01-12.098 5.012 16.986 16.986 0 01-12.097-5.011 16.985 16.985 0 01-5.012-12.097c0-4.573 1.779-8.864 5.012-12.097a17.002 17.002 0 0112.097-5.012c4.565 0 8.864 1.779 12.097 5.012a16.986 16.986 0 015.012 12.097c0 4.572-1.778 8.864-5.012 12.097h13.606c.163-.345.322-.692.472-1.044a28.238 28.238 0 002.232-11.06c0-3.83-.749-7.554-2.232-11.06a28.315 28.315 0 00-6.085-9.03A28.33 28.33 0 00125.732-54a28.33 28.33 0 00-20.089 8.317 28.315 28.315 0 00-6.085 9.03 28.236 28.236 0 00-2.232 11.06 28.32 28.32 0 008.317 20.09 28.33 28.33 0 0020.089 8.316c3.83 0 7.554-.749 11.06-2.232a28.9 28.9 0 001.037-.468z"
                className="c1"
              ></path>
              <path
                fill="#f13b08"
                stroke="#f13b08"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M129.5293 129.43994L111.1329 111.04355 96.04421 110.80716 114.67699 129.43994z"
                className="c3"
                transform="translate(26.855 -124.56)"
              ></path>
            </g>
            <g ariaLabel="U" transform="matrix(.75 0 0 .75 42.134 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M173.445 1.172a22.132 22.132 0 01-7.057-4.76 22.245 22.245 0 01-4.76-7.057 22.098 22.098 0 01-1.75-8.648V-53.64h11.306v34.347c0 6.012 4.897 10.909 10.91 10.909 6.012 0 10.908-4.897 10.908-10.91V-53.64h11.305v34.347c0 2.995-.59 5.904-1.75 8.648a22.118 22.118 0 01-4.76 7.057 22.26 22.26 0 01-7.056 4.76 22.097 22.097 0 01-8.648 1.749c-2.996 0-5.904-.59-8.648-1.75z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="A" transform="matrix(.75 0 0 .75 53.384 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M85.77515 128.08252L99.93921 89.14844 114.22534 128.08252 126.19214 128.08252 104.82788 71.91748 95.08569 71.91748 73.80786 128.08252z"
                className="c1"
                transform="translate(129.547 -125.557)"
              ></path>
              <path
                fill="#f13b08"
                stroke="#f13b08"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M100.03819 128.08252L91.09854 114.24284 108.90146 114.24284z"
                className="c3"
                transform="translate(129.547 -125.557)"
              ></path>
            </g>
            <g ariaLabel="L" transform="matrix(.75 0 0 .75 64.634 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M257.699-50.04l11.304-3.6v45.436h25.275V2.525h-36.58z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="R" transform="matrix(.75 0 0 .75 75.884 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M341.585 2.525h-13.66l-13.37-19.276v-10.967h2.16c3.405 0 5.954-.749 7.567-2.232 1.383-1.274 2.081-3.11 2.081-5.473 0-2.462-.72-4.334-2.138-5.558-1.454-1.26-3.76-1.93-6.647-1.93h-8.496V2.525h-11.305V-53.64h20.665c3.162 0 6.013.454 8.49 1.346 2.441.886 4.529 2.211 6.207 3.932 3.103 3.19 4.745 7.712 4.745 13.083 0 4.818-1.332 8.8-3.96 11.838-1.83 2.11-4.241 3.723-7.2 4.81z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="I" transform="matrix(.75 0 0 .75 87.134 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M344.403-50.04l11.305-3.6V2.525h-11.305z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="S" transform="matrix(.75 0 0 .75 98.384 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M361.074-5.763l-1.52-1.628 7.439-8.9 1.921 2.413a16.023 16.023 0 005.675 4.471c2.073.98 4.313 1.49 6.473 1.49 2.55 0 4.724-.561 6.121-1.576 1.26-.915 1.872-2.139 1.872-3.752 0-1.757-.54-2.844-1.915-3.874-1.585-1.18-4.148-2.146-7.122-3.269-.252-.093-.51-.194-.777-.295-4.083-1.52-8.007-3.082-11.155-5.587-1.828-1.455-3.182-3.061-4.14-4.904-1.065-2.045-1.605-4.37-1.605-6.913 0-4.428 1.915-8.525 5.386-11.536 3.37-2.916 7.898-4.521 12.76-4.521 6.278 0 12.406 2.26 17.713 6.53l1.916 1.55-6.907 8.985-2.015-1.656c-3.55-2.93-7.453-4.608-10.707-4.608-3.025 0-6.568 1.31-6.625 5.004-.022 1.65.77 2.549 1.972 3.392 1.722 1.21 4.457 2.253 7.353 3.362l.028.007c.454.18.907.36 1.362.533 4.096 1.606 7.97 3.118 10.908 5.538 3.47 2.865 5.163 6.638 5.163 11.542 0 2.657-.569 5.127-1.693 7.344-1.058 2.075-2.57 3.882-4.514 5.372-3.542 2.708-8.317 4.206-13.45 4.206-7.59 0-14.661-3.096-19.917-8.72z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="E" transform="matrix(.75 0 0 .75 109.634 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M405.496 2.525h36.362V-7.989h-36.362zm0-22.61h36.362v-10.73h-36.362zm0-33.555v10.729h36.362V-53.64z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="C" transform="matrix(.75 0 0 .75 120.884 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M482.971.775a28.526 28.526 0 01-9.094-6.135 28.627 28.627 0 01-6.135-9.094 28.484 28.484 0 01-2.253-11.14c0-3.859.755-7.61 2.253-11.139a28.512 28.512 0 016.135-9.094 28.639 28.639 0 019.094-6.135 28.365 28.365 0 0111.14-2.254 28.6 28.6 0 0123.79 12.702l-9.389 6.294a17.303 17.303 0 00-14.401-7.69 17.23 17.23 0 00-12.248 5.069c-3.277 3.269-5.07 7.625-5.07 12.248 0 4.623 1.8 8.972 5.07 12.248a17.201 17.201 0 0012.248 5.068c5.76 0 11.132-2.857 14.365-7.638l9.368 6.328a28.605 28.605 0 01-23.733 12.616c-3.86 0-7.61-.756-11.14-2.254z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="O" transform="matrix(.75 0 0 .75 132.134 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M534.791.581a28.338 28.338 0 01-9.03-6.084 28.343 28.343 0 01-8.317-20.09 28.338 28.338 0 018.317-20.09A28.337 28.337 0 01545.851-54a28.33 28.33 0 0120.09 8.317 28.315 28.315 0 016.085 9.03 28.236 28.236 0 012.232 11.06 28.32 28.32 0 01-8.317 20.09 28.33 28.33 0 01-20.09 8.316c-3.83 0-7.553-.749-11.06-2.232zm-1.037-38.264a16.986 16.986 0 00-5.012 12.097c0 4.572 1.779 8.864 5.012 12.097a16.986 16.986 0 0012.097 5.011c4.573 0 8.864-1.778 12.097-5.011a16.99 16.99 0 005.012-12.097 16.99 16.99 0 00-5.012-12.097 17.002 17.002 0 00-12.097-5.012 17.002 17.002 0 00-12.097 5.012z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="N" transform="matrix(.75 0 0 .75 143.384 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M590.613-32.55V2.525h-11.305V-53.64h10.016l25.123 35.398V-53.64h11.306V2.525H615.52z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="S" transform="matrix(.75 0 0 .75 154.634 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M630.792-5.763l-1.52-1.628 7.44-8.9 1.921 2.413a16.023 16.023 0 005.675 4.471c2.073.98 4.312 1.49 6.473 1.49 2.55 0 4.723-.561 6.12-1.576 1.26-.915 1.873-2.139 1.873-3.752 0-1.757-.54-2.844-1.915-3.874-1.585-1.18-4.149-2.146-7.122-3.269-.252-.093-.511-.194-.778-.295-4.083-1.52-8.006-3.082-11.154-5.587-1.829-1.455-3.182-3.061-4.14-4.904-1.066-2.045-1.606-4.37-1.606-6.913 0-4.428 1.916-8.525 5.387-11.536 3.37-2.916 7.898-4.521 12.76-4.521 6.278 0 12.406 2.26 17.712 6.53l1.916 1.55-6.906 8.985-2.016-1.656c-3.55-2.93-7.453-4.608-10.707-4.608-3.024 0-6.567 1.31-6.625 5.004-.021 1.65.77 2.549 1.973 3.392 1.722 1.21 4.457 2.253 7.353 3.362l.028.007c.454.18.907.36 1.361.533 4.097 1.606 7.971 3.118 10.908 5.538 3.471 2.865 5.164 6.638 5.164 11.542 0 2.657-.569 5.127-1.693 7.344-1.058 2.075-2.57 3.882-4.514 5.372-3.542 2.708-8.317 4.206-13.45 4.206-7.59 0-14.661-3.096-19.918-8.72z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="U" transform="matrix(.75 0 0 .75 165.884 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M688.132 1.172a22.132 22.132 0 01-7.056-4.76 22.245 22.245 0 01-4.76-7.057 22.098 22.098 0 01-1.75-8.648V-53.64h11.306v34.347c0 6.012 4.896 10.909 10.909 10.909 6.012 0 10.908-4.897 10.908-10.91V-53.64h11.306v34.347c0 2.995-.591 5.904-1.75 8.648a22.118 22.118 0 01-4.76 7.057 22.26 22.26 0 01-7.057 4.76 22.097 22.097 0 01-8.647 1.749c-2.996 0-5.905-.59-8.649-1.75z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="L" transform="matrix(.75 0 0 .75 177.134 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M725.98-50.04l11.305-3.6v45.436h25.274V2.525H725.98z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="T" transform="matrix(.75 0 0 .75 188.384 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M773.07-42.911h-15.266V-53.64h42.052v10.729h-15.482V2.525H773.07z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="I" transform="matrix(.75 0 0 .75 199.634 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M803.543-50.04l11.305-3.6V2.525h-11.305z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="N" transform="matrix(.75 0 0 .75 210.884 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M834.175-32.55V2.525h-11.304V-53.64h10.015l25.123 35.398V-53.64h11.306V2.525h-10.232z"
                className="c1"
              ></path>
            </g>
            <g ariaLabel="G" transform="matrix(.75 0 0 .75 222.134 402.495)">
              <path
                fill="#323232"
                stroke="#323232"
                strokeLinejoin="miter"
                strokeMiterlimit="2"
                strokeWidth="0"
                d="M891.096.746a28.546 28.546 0 01-15.157-15.164 28.36 28.36 0 01-2.24-11.09c0-3.845.756-7.575 2.24-11.096a28.47 28.47 0 016.106-9.058A28.354 28.354 0 01902.192-54c4.559 0 9.102 1.102 13.12 3.197a28.691 28.691 0 019.9 8.511l-9.13 6.668a17.284 17.284 0 00-5.984-5.141 17.008 17.008 0 00-7.913-1.923 17.07 17.07 0 00-12.155 5.033 17.069 17.069 0 00-5.033 12.155c0 4.594 1.786 8.907 5.033 12.154a17.067 17.067 0 0012.155 5.034c4.594 0 8.907-1.786 12.154-5.034a17.151 17.151 0 004.09-6.502h-16.237v-11.304h28.493v5.652c0 3.845-.756 7.575-2.24 11.096a28.485 28.485 0 01-6.106 9.058 28.313 28.313 0 01-9.058 6.107 28.606 28.606 0 01-11.089 2.224c-3.845 0-7.574-.755-11.096-2.239z"
                className="c1"
              ></path>
            </g>
          </g>
          <image x="0" y="0" display="none"></image>
        </g>
      </g>
      <path
        fill="#ff8080"
        strokeWidth="0.591"
        d="M28.658 39.294l-6.48-6.5h10.045l6.48 6.5 6.48 6.5H35.136z"
      ></path>
      <path
        fill="#ff8080"
        strokeWidth="0.591"
        d="M120.648 39.237l-3.02-4.67h5.888c3.238 0 5.887.124 5.887.277 0 .152-1.29 2.254-2.868 4.67l-2.868 4.394z"
      ></path>
    </svg>
  );
}

export default Icon;
